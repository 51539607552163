import { Page } from "../../components";
import { ReactNode } from "react";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";

const updateFebruary262024: ReactNode = (
    <div className="paragraph">
        <p>
            <strong>1.0.0</strong>
        </p>
        <ul>
            <li>Global release of Zenith</li>
        </ul>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Release Notes",
    breadCrumbItems: ["Zenith", "Release Notes"],
    isBeta: true
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "zenith-february-26-2024",
        summary: "February 26, 2024",
        details: updateFebruary262024
    }
];

export default function ReleaseNotes() {
    return <Page section={HeaderSections.Zenith} pageTitle={pageTitle} tableOfContents={pageSections} />;
}
