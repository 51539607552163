// TODO - remove this file and use the rate limits from xml file when xml is updated
// convert the list of entity rate limits in INTGR-1141 into a dictionary to associate rate limits with their corresponding entities and methods.

interface IRateLimitMethodMapping {
    [key: string]: {

        limit: string;
        period: string;
        isMonitorMode: boolean;
        isApplyTier: boolean;
    };
}
interface IRateLimitObjectMapping {
    [key: string]: {
        [key: string]: {
            limit: string;
            period: string;
            isMonitorMode: boolean;
            isApplyTier: boolean;
        };
    };
}

export const objectRateLimitsMapping: IRateLimitObjectMapping = {
    " * ": {
        "add": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "addtoqueue": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "countuniqueentitiesforgroups": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getcountof": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getdashboard": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getdashboardjson": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getexcel": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeedversion": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "remove": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "removeall": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "sendreportprocessingrequest": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "set": {
            "limit": "100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "addindata": {
        "get": {
            "limit": "650",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "remove": {
            "limit": "2250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "set": {
            "limit": "1700",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "annotationlog": {
        "get": {
            "limit": "650",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "audit": {
        "add": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "backfillstatus": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "binarydata": {
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "chargeevent": {
        "get": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "controller": {
        "get": {
            "limit": "750",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "customdata": {
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "customreportschedule": {
        " * ": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "debugdata": {
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "defect": {
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "device": {
        " * ": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "650",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getcountof": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "deviceshare": {
        "get": {
            "limit": "800",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "devicestatusinfo": {
        "get": {
            "limit": "900",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "diagnostic": {
        "get": {
            "limit": "650",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "distributionlist": {
        "get": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "driver": {
        "add": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "driverchange": {
        " * ": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "driverregulation": {
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "dutystatusavailability": {
        "get": {
            "limit": "2500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "dutystatuslog": {
        " * ": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "400",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "dutystatusviolation": {
        "get": {
            "limit": "750",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "dvirlog": {
        " * ": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "emissionvehiclecycle": {
        "get": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "emissionvehiclecyclemessage": {
        "add": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "energysnapshotrecord": {
        "get": {
            "limit": "1500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "entitysetting": {
        " * ": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "400",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "eventtype": {
        "get": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "exceptionevent": {
        "get": {
            "limit": "2000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "failuremode": {
        "get": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "faultdata": {
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "set": {
            "limit": "1200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "fillup": {
        "get": {
            "limit": "1200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "fueltaxdetail": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "fueltransaction": {
        " * ": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "fuelupevent": {
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "fuelused": {
        "get": {
            "limit": "1500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "group": {
        " * ": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "groupdevice": {
        "add": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "750",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "groupfilter": {
        "get": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "ioxaddon": {
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "logrecord": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeedversion": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "logrecordzonedistance": {
        "get": {
            "limit": "800",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "mediafile": {
        "get": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "notification": {
        "add": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "propertyset": {
        "get": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "reportstorage": {
        "get": {
            "limit": "1500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "reporttemplate": {
        " * ": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "restricteddatamodechange": {
        "get": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "route": {
        " * ": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "routeextract": {
        "set": {
            "limit": "4500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "rule": {
        " * ": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "shipmentlog": {
        "get": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeedversion": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "set": {
            "limit": "350",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "statusdata": {
        "add": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeedversion": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "sustainabilityelectrificationsummary": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "sustainabilitygroupidlingsummary": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "sustainabilitymonthlysummary": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "systemsettings": {
        "get": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "tachographcompanycard": {
        "set": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "tachographdatafile": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "textmessage": {
        " * ": {
            "limit": "99999",
            "period": "1s",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "99999",
            "period": "1s",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "trailer": {
        " * ": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "trailerattachment": {
        " * ": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "300",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "trip": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "tripannotation": {
        "add": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "3500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "triptypechange": {
        "get": {
            "limit": "450",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "user": {
        " * ": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "500",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        }
    },
    "userdevicelink": {
        "get": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "userdisplayprofile": {
        " * ": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "250",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "userhosruleset": {
        "get": {
            "limit": "1100",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "zone": {
        " * ": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "add": {
            "limit": "900",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "get": {
            "limit": "750",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        },
        "getfeed": {
            "limit": "200",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": false
        },
        "remove": {
            "limit": "1000",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    },
    "zonetype": {
        "get": {
            "limit": "150",
            "period": "1m",
            "isMonitorMode": true,
            "isApplyTier": true
        }
    }
};


export const methodRateLimitsMapping: IRateLimitMethodMapping = {
    " * ": {
        "limit": "100",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": false
    },
    "addioxentities": {
        "limit": "100",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "decodevins": {
        "limit": "1000",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "dismissnotification": {
        "limit": "150",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getaceresults": {
        "limit": "2000",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getactiveinsights": {
        "limit": "150",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getaddresses": {
        "limit": "450",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getbigdataresults": {
        "limit": "99999",
        "period": "1s",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getcoordinates": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getdatabasefile": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getdaylightsavingrules": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getdevicehistory": {
        "limit": "700",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getdirections": {
        "limit": "200",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getlastknownpositions": {
        "limit": "650",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getlivemessages": {
        "limit": "700",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getmapkey": {
        "limit": "150",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getnearestroad": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getnotification": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getnotificationwebrequesttemplates": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getpostedroadspeedsfordevice": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getreportdata": {
        "limit": "400",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getroadmaxspeeds": {
        "limit": "300",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getroadmaxspeedsfordevice": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getstockruletemplate": {
        "limit": "150",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getsystemtimeutc": {
        "limit": "500",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "gettachographmodulestatus": {
        "limit": "1500",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getusernotifications": {
        "limit": "200",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "getweather": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "notify": {
        "limit": "150",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "settachographdatafilearchivedinbulk": {
        "limit": "200",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "tachographinternal": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "tachographreporting": {
        "limit": "250",
        "period": "1m",
        "isMonitorMode": true,
        "isApplyTier": true
    },
    "tracetext": {
        "limit": "99999",
        "period": "1s",
        "isMonitorMode": true,
        "isApplyTier": true
    }
};
