import { ReactNode } from "react";
import { Page } from "./../../components";
import { PageTitleProps } from "./../../components/PageTitle/PageTitle";
import { HeaderSections } from "./../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "./../../components/TableOfContents/TableOfContents";
import InformationalBox from "../../components/InformationalBox/InformationalBox";
import CodeSample from "../../components/CodeSamplesContainer/CodeSample";
import Approved from "../../assets/images/zenith/setup/zenithApproved.png";
import Container from "../../assets/images/zenith/setup/zenithContainer.png";
import Colors from "../../assets/images/zenith/setup/zenithColours.png";
import PageName from "../../assets/images/zenith/setup/zenithPageNameEnglish.png";
import PageNameJapanese from "../../assets/images/zenith/setup/zenithPageNameJapanese.png";

const react: ReactNode = (
    <div className="paragraph">
        Zenith library is a set of React components. To learn more, visit the React documentation{" "}
        <a href="https://react.dev/learn" target="_blank" rel="noopener noreferrer" aria-label="React quick start guide">
            Quick Start
        </a>{" "}
        page.
    </div>
);

const projectInstallationAndSetup: ReactNode = (
    <div className="paragraph">
        <p>
            Zenith is distributed as an npm package on{" "}
            <a href="https://www.npmjs.com/" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                npmjs.com
            </a>
            .
        </p>
        <p>Go to the root of your project and run the following command:</p>
        <CodeSample language="bash" code={`npm install @geotab/zenith --save`} />
    </div>
);

const importingStyles: ReactNode = (
    <div className="paragraph">
        <p>
            Styles in Zenith library are located in the css file
            <code className="zenith-path">@geotab/zenith/dist/index.css</code>, which should be included in your project. The following is an example of how to include them if your builder handles
            css imports:
        </p>
        <CodeSample
            language="javascript"
            code={`import React, { useCallback, useMemo, useState } from "react"
import "@geotab/zenith/dist/index.css"`}
        />
        <p>It can also be imported in your less/sass file.</p>
    </div>
);

const usage: ReactNode = (
    <div className="paragraph">
        <p>If you have followed the installation process correctly, you can now import components from the package:</p>
        <CodeSample
            language="javascript"
            code={`import React, { useCallback, useMemo, useState } from "react"
import { Menu, ButtonType, Button, IconSettings3 } from "@geotab/zenith";`}
        />
        <p>Components are now ready for use</p>
    </div>
);

const availableComponents: ReactNode = (
    <div className="paragraph">
        The Zenith component library, based on the Zenith Design System, provides a wide range of UI elements for building consistent and engaging interfaces. These elements include:
        <ul>
            <li>
                <strong>Foundational element: </strong>Colors, icons, and font presets.
            </li>
            <li>
                <strong>Atomic components: </strong>Basic building blocks like buttons, checkboxes, and input fields.
            </li>
            <li>
                <strong>Higher-order components: </strong>Complex components such as headers, tables, and filter bars.
            </li>
        </ul>
        <p>
            A complete list of available components and usage examples can be found in{" "}
            <a href="https://developers.geotab.com/zenith-storybook/" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                Storybook
            </a>{" "}
            .
        </p>
        <img src={Colors} alt="Screenshot of Zenith Storybook Colours page." className="components-picture" />
    </div>
);

const colors: ReactNode = (
    <div className="paragraph">
        <p>
            Zenith provides a defined color palette, where each color has a specific name, purpose, and value. This palette ensures consistency and accessibility across all applications. Colors are
            defined as CSS custom properties, offering flexibility and maintainability. They can be used within Zenith components and throughout any application for a unified style.
        </p>
        <p>
            Explore the full color palette and usage guidelines in{" "}
            <a href="https://developers.geotab.com/zenith-storybook/?path=/docs/global-styles-colors--docs" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                Storybook
            </a>{" "}
            .
        </p>
        <CodeSample
            language="css"
            code={`:root {
    // text
    --text-primary:                     #1F2833;
    --text-secondary:                   #4E677E;
    --text-placeholder:                 #8DA4B9;
    --text-hyperlink:                   #005AA8;
    --text-nav-highlight:               #0069BF;
    --text-error-message:               #C51A11;
    --text-button-disabled:             #C0CCD8;
    --text-reverse-primary:             #FFFFFF;
    --text-reverse-primary-button:      var(--text-reverse-primary);
    
    // backgrounds
    --backgrounds-main:                     #FFFFFF;
    --backgrounds-content-0:                #F9FAFB;
    --backgrounds-content-1:                #F2F5F7;
    --backgrounds-content-2:                #D9E1E8;
    --backgrounds-hover:                    #E6EBEF;
    --backgrounds-field-disabled:           #E6EBEF;
    --backgrounds-text-button-disabled:     #C0CCD8;
    --backgrounds-overlay:                  #rgba(31, 40, 51, 0.4);
    --backgrounds-primary-brand:            #25477B;
    --backgrounds-reverse-2:                #2F5B9D;
    --backgrounds-reverse-1:                #1F2833;
    --backgrounds-reverse-0:                #3E5265;
    
    ...`}
        />
        <p>The following is an example of how Zenith colors can be used:</p>
        <CodeSample
            language="css"
            code={`.zen-secondary-colors--active{
    color: var(--text-reverse-primary)
    fill:var(--text-reverse-primary);
    border-color: var(--action-secondary--hover);
    background-color: var(--action-secondary--hover);
}`}
        />
    </div>
);

const typography: ReactNode = (
    <div className="paragraph">
        <p>
            Zenith defines typography presents for various use cases. These styles can be applied to your components, assigned class names to your HTML elements, or integrated into your classes (if
            you are using CSS-preprocessors such as less or sass.
        </p>
        <p>
            Explore the full range of typography presets in{" "}
            <a href="https://developers.geotab.com/zenith-storybook/?path=/docs/global-styles-typography--docs" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                Storybook
            </a>
            .
        </p>
        <CodeSample
            language="html"
            code={`return <>
    <div className="body-04">I'm body-04!</div>
</>;
`}
        />
        <p>Components are now ready for use.</p>
    </div>
);

const caption: ReactNode = (
    <div className="paragraph">
        Caption is a set of CSS class names which enables positioning two or three elements with default padding. The helper aligns elements vertically and sets default spacing between them. It
        consists of the following classes:
        <ul>
            <li>zen-caption — the parent element</li>
            <li>zen-caption__pre-content — the first element (usually an icon)</li>
            <li>zen-caption__content — the main content element (usually a text)</li>
            <li>zen-caption__post-content — an additional element at the end (usually an icon)</li>
        </ul>
        <p>The most popular use case is "Icon + Text". It consists of the following CSS classes:</p>
        <CodeSample
            language="html"
            code={`
    return (
    <Button className="zen-caption">
        <IconCheck className="zen-caption__pre-content"></IconCheck>
        <span className="zen-caption__content">Approved</span>
    </Button>
    );
  `}
        />
        <br />
        <img src={Approved} alt="Example button with text and a checkmark icon." className="approved-picture" />
    </div>
);

const link: ReactNode = (
    <div className="paragraph">
        Link is a class name used to apply Zenith styles to a link. It also uses the following modifications:
        <ul>
            <li>zen-link zen-link—disabled — disable the style of a link</li>
            <li>zen-link zen-link—inline — remove paddings/margins (This is useful when a link is embedded in text)</li>
            <li>zen-link zen-link—hidden — applies "display:none" and makes the link invisible</li>
            <li>zen-link zen-link—light — displays the link without an underline</li>
        </ul>
        <CodeSample language="html" code={`return <a className="https://geotab.com" className ="zen-link">Link to website</a>`} />
    </div>
);

const uiComponents: ReactNode = (
    <div className="paragraph">
        <p>
            Zenith provides a comprehensive set of UI components which can be combined like building blocks to create complex user interfaces. All components are fully independent and can be used in
            any combination. A full list of components can be found in{" "}
            <a href="https://developers.geotab.com/zenith-storybook/" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                Storybook
            </a>
            .
        </p>
        <p>Components should be imported from the npm package prior to use:</p>
        <CodeSample
            language="javascript"
            code={`import React from "react";
import{
    Header,
    IconCheck,
    IconLoader,
    IListColumn,
    Menu,
    Pill,
    Table,
    useMobile
} from "@geotab/zenith";`}
        />
        <p>Some components are not available in the index file of the npm package and must be imported directly from the file as below:</p>
        <CodeSample language="javascript" code={`import { normalizeVertically } from "@geotab/zenith/dist/utils/positioningUtils/normalizeVertically";`} />
    </div>
);

const containers: ReactNode = (
    <div className="paragraph">
        <p>
            Zenith offers versatile container components like Card and Summary tiles. These provide features like titles, tooltips, and menus, while allowing you to customize the content within. For
            example, a Card can display anything from page summary information to line charts, all with consistent styling for borders, titles, and interactive elements.
        </p>
        <img src={Container} alt="Line graph of average collision risk with sidebar labels." className="container-picture" />
    </div>
);

const userLanguageAndDateFormat: ReactNode = (
    <div className="paragraph">
        <p>Zenith includes components such as DateRange, which relies on date and time format. Use UserFormatProvider to wrap your application and set date formats as below:</p>
        <p>
            Use the properties dateFormat and language to configure both date and time format and language in the current user object. FiltersBar.PeriodPicker will format dates according to your
            settings:
        </p>
        <CodeSample
            language="html"
            code={`
    return (
        <UserFormatProvider dateFormat="dd MMMM yyyy" timeFormat="hh:mm" weekStartsOnSunday={false}>
          <Header onFiltersBarOpen={() => setIsAllFiltersVisible(true)}>
            <Header.Title pageName="Page Name" />
            <FiltersBar isAllFiltersVisible={isAllFiltersVisible} ...>
              <FiltersBar.PeriodPicker id="dateRange" showInSidePanel ... />
            </FiltersBar>
          </Header>
        </UserFormatProvider>
    );
  `}
        />

        <br />
        <img src={PageName} alt="Top of page with title and beta pill above a search bar and date dropdown." className="pagename-picture" />
        <CodeSample
            language="javascript"
            code={`
    return (
        <LanguageProvider language="ja">
          <Header>
            <Header.Title pageName="Page Name" isBeta={true} />
          </Header>
        </LanguageProvider>
    );
  `}
        />

        <br />
        <img src={PageNameJapanese} alt="Top of page with english title and japanese beta pill." className="pagename-translated-picture" />
        <InformationalBox>
            <p>
                Zenith only provides translation for built-in strings. In the example above, only the beta pill will be translated by Zenith; Page name must be translated prior to using it in the
                component.
            </p>
        </InformationalBox>
    </div>
);

const troubleshooting: React.ReactNode = (
    <div className="paragraph">
        <p>
            Please visit our Zenith <a href="https://community.geotab.com/s/group/0F9Pd0000003b33KAA/geotab-zenith" target="_blank" rel="noopener noreferrer" aria-label="zenith forum link">community page</a> or contact{" "}
            <a href="mailto:zenith@geotab.com" target="_blank" rel="noopener noreferrer" aria-label="Feedback Email">
                zenith@geotab.com
            </a>{" "}
            if you have any questions or feedback.
        </p>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Setup",
    breadCrumbItems: ["Zenith", "Setup"],
    isBeta: true
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "react",
        summary: "React",
        details: react
    },
    {
        elementId: "project-installation-and-setup",
        summary: "Project Installation and setup",
        details: projectInstallationAndSetup
    },
    {
        elementId: "importing-styles",
        summary: "Importing styles",
        details: importingStyles
    },
    {
        elementId: "usage",
        summary: "Usage",
        details: usage
    },
    {
        elementId: "available-components",
        summary: "Available components",
        details: availableComponents
    },
    {
        elementId: "colors",
        summary: "Colors",
        details: colors
    },
    {
        elementId: "typography",
        summary: "Typography",
        details: typography
    },
    {
        elementId: "caption",
        summary: "Caption",
        details: caption
    },
    {
        elementId: "link",
        summary: "Link",
        details: link
    },
    {
        elementId: "ui-components",
        summary: "UI components",
        details: uiComponents
    },
    {
        elementId: "containers",
        summary: "Containers",
        details: containers
    },
    {
        elementId: "user-language-and-date-format",
        summary: "User language and date format",
        details: userLanguageAndDateFormat
    },
    {
        elementId: "troubleshooting",
        summary: "Troubleshooting",
        details: troubleshooting
    }
];

export default function Setup() {
    return (
        <Page section={HeaderSections.Zenith} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                <InformationalBox>
                    <p>
                        By using Zenith, you consent to all terms of use. Full terms of use can be seen{" "}
                        <a
                            href="https://docs.google.com/document/d/17DJX3BAIgVq0APHiENi3dN2KFEN9BqgiSahsDGpQaCo/edit?tab=t.0"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Zenith Terms of use"
                        >
                            here
                        </a>
                        .
                    </p>
                </InformationalBox>
            </div>
        </Page>
    );
}
