import { ApiMethodParameter, ApiMethodData } from "../ApiMethod";
import { ApiObjectProperty, ApiObjectData } from "../ApiObject";
import { extractCodeReference, extractMember, extractLink, handleReturnsTag, isId, isLinkTag, isTextTag, WHITESPACE_REGEX, SPECIAL_CHAR_REGEX } from "./parserUtils";

//ToDo: Parser needs to be refactored to reduce complexity
interface MyAParameterInfo extends ApiMethodParameter {
    isRequired: boolean;
    isSupported: boolean;
}

interface MyAPropertyInfo extends ApiObjectProperty {
    isSupported: boolean;
}

export interface MyAMethodInfo extends ApiMethodData {
    parameters: MyAParameterInfo[];
    roles: string[];
    isSupported: boolean;
    remarks: string;
}

export interface MyAObjectInfo extends ApiObjectData {
    properties: MyAPropertyInfo[];
    isSupported: boolean;
    hasValues: boolean;
    remarks: string;
}

export interface MyAParserOutput {
    [name: string]: MyAMethodInfo | MyAObjectInfo;
}

export default function myAParser(xml: XMLDocument, itemType: string): MyAParserOutput {
    const json: { [key: string]: MyAMethodInfo | MyAObjectInfo } = {};

    const processNode = (node: Element) => {
        const memberName = node.attributes.getNamedItem("name")?.nodeValue ?? "";

        if (itemType === "method" && isMethod(memberName)) {
            const methodName = extractMethodName(memberName);
            const methodInfo = parseMethodInfo(node, memberName);
            if (!(methodName in json) && methodInfo.isSupported) {
                json[methodName] = methodInfo;
            }
        } else if (itemType === "object" && isObject(memberName)) {
            const memberNameArray = memberName.split(".");
            const objectName = memberNameArray[memberNameArray.length - 1].replace(SPECIAL_CHAR_REGEX, "");
            const objectInfo = parseObjectInfo(node);
            if (!(objectName in json) && objectInfo.isSupported && objectName !== "MyAdminApiService") {
                json[objectName] = objectInfo;
            }
        } else if (itemType === "object" && isObjectProperty(memberName)) {
            const memberNameArray = memberName.split(".");
            const objectName = memberNameArray[memberNameArray.length - 2].replace(SPECIAL_CHAR_REGEX, "");
            const objectPropertyName = memberNameArray[memberNameArray.length - 1].replace(SPECIAL_CHAR_REGEX, "");
            const objectPropertyInfo = parseObjectPropertiesInfo(node, objectPropertyName);
            if (objectName in json && json[objectName].hasOwnProperty("properties")) {
                (json[objectName] as MyAObjectInfo).properties.push(objectPropertyInfo);
            }
        }
    };

    const members = xml.documentElement.getElementsByTagName("member");
    Array.from(members).forEach((memberNode) => processNode(memberNode));
    transformJSON(json);
    sortJSON(json);
    return json;
}

function extractMethodName(input: string): string {
    const webMethodsMatch = input.match(/MyAdminApiService\.([a-zA-Z]+)/);
    if (webMethodsMatch) {
        return webMethodsMatch[1];
    }
    return "";
}

function isMethod(memberName: string): boolean {
    return memberName.includes("M:Geotab.Internal.MyAdmin") || memberName.includes("M:Geotab.Checkmate.ObjectModel");
}

function isObject(memberName: string): boolean {
    return memberName.includes("T:");
}

function isObjectProperty(memberName: string): boolean {
    return memberName.includes("P:") || memberName.includes("F:");
}

function isMyAObject(parameterName: string): boolean {
    const isServer = typeof window === "undefined";
    if (isServer) {
        return false;
    }
    const storageKey = `${parameterName.charAt(0).toUpperCase() + parameterName.slice(1)}MYA`;
    const storedItem = sessionStorage.getItem(storageKey);
    const item = storedItem ? JSON.parse(storedItem) : null;

    return item?.hasOwnProperty("properties") ?? false;
}

function parseMethodInfo(xmlMemberElement: Element, memberName: string): MyAMethodInfo {
    let method: MyAMethodInfo = {
        description: "",
        parameters: [],
        roles: [],
        returns: "",
        example: "",
        isSupported: false,
        remarks: ""
    };
    const dataTypeArray: string[] = getParameterList(memberName);
    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChildElement.nodeName;
        if (tagName === "isSupported") {
            if (memberChildElement.innerHTML === "true") {
                method.isSupported = true;
            }
        }
        if (tagName === "param") {
            if (memberChildElement.getAttribute("name") === "cancellationToken") {
                dataTypeArray.shift()!;
            } else {
                handleParamTag(method, memberChildElement, dataTypeArray);
            }
        }
        if (tagName === "remarks") {
            handleRemarksTag(method, memberChildElement);
        }
        if (tagName === "returns") {
            handleReturnsTag(method, memberChildElement);
        }
        if (tagName === "roles") {
            const rolesText = memberChildElement.textContent;
            method.roles = rolesText?.split(",").map((role) => role.trim()) || [];
        }
        if (tagName === "summary") {
            handleMethodSummaryTag(method, memberChildElement);
        }
    });
    return method;
}

function parseObjectInfo(xmlMemberElement: Element): MyAObjectInfo {
    let object: MyAObjectInfo = {
        description: "",
        properties: [],
        isSupported: false,
        hasValues: false,
        remarks: ""
    };
    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChild.nodeName;
        if (tagName === "isSupported") {
            if (memberChildElement.innerHTML === "true") {
                object.isSupported = true;
            }
        }
        if (tagName === "summary") {
            handleObjectSummaryTag(object, memberChildElement);
        }
    });
    return object;
}

function parseObjectPropertiesInfo(xmlMemberElement: Element, propertyName: string): MyAPropertyInfo {
    let objectProperty: MyAPropertyInfo = {
        name: propertyName,
        description: "",
        isBeta: false,
        isSupported: true,
        dataType: ""
    };

    const codeReference = extractCodeReference(xmlMemberElement);
    const entity = extractMember(codeReference);
    const dataType = codeReference && isId(entity) ? "String" : entity;
    objectProperty.dataType = dataType;

    let descriptionText = "";
    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChildElement.nodeName;
        if (tagName === "summary") {
            const summaryChildren = memberChildElement.childNodes;
            summaryChildren.forEach((summaryChild) => {
                const summaryChildElement = summaryChild as Element;
                const summaryTagName = summaryChildElement.nodeName;
                if (isLinkTag(summaryTagName)) {
                    descriptionText += extractLink(summaryChildElement);
                }
                if (isTextTag(summaryTagName)) {
                    descriptionText += summaryChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
                }
            });
        }
    });
    objectProperty.description = descriptionText.trimStart();
    return objectProperty;
}

// Extracts and processes parameter data types from a memberName string, filtering out empty parameters and specific values, and converting long data type names to short ones.
// Example input: M:Geotab.Internal.MyAdmin.Api.Handlers.Legacy.MyAdminApiService.Authenticate(System.String,System.String)
// Example output: ['String', 'String']
function getParameterList(memberName: string): string[] {
    const openingParenthesisIndex = memberName.indexOf("(");
    const parametersString = openingParenthesisIndex > -1 ? memberName.substring(openingParenthesisIndex + 1, memberName.length - 1) : "";
    const parameterArray = parametersString
        .split(",")
        .filter((item) => item.trim() !== "``0" && item.trim() !== "")
        .map((item) => {
            if (item.includes("Collections")) {
                return "List";
            }
            let member = extractMemberFromFullyQualifiedName(item);
            return isMyAObject(member) ? (member = "Object") : member;
        });
    return parameterArray;
}

//  Transforming fully qualified type names into simpler forms
//  Example input: System.Nullable{System.Int32}
//  Example output: Int32
//  Example input: Geotab.Checkmate.ObjectModel.Device
//  Example output: Device
function extractMemberFromFullyQualifiedName(fqn: string): string {
    const prefixPattern = /(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*/;
    const nullablePattern = /Nullable\{\s*System\.\s*([A-Za-z0-9]+)\s*\}/;
    const regularPattern = /([A-Za-z0-9]+)/;

    const nullableRegex = new RegExp(`${prefixPattern.source}${nullablePattern.source}`);
    const regularRegex = new RegExp(`${prefixPattern.source}${regularPattern.source}`);

    const nullableMatch = nullableRegex.exec(fqn);
    if (nullableMatch) {
        return nullableMatch[1]; // Return the nullable type
    }

    const regularMatch = regularRegex.exec(fqn);
    if (regularMatch) {
        return regularMatch[1]; // Return the regular type
    }
    return fqn;
}

function transformJSON(json: { [key: string]: MyAMethodInfo | MyAObjectInfo }) {
    Object.keys(json).forEach((key) => {
        const value = json[key];
        // Helper function to process a list of items
        function processItems(items: { dataType: string }[]) {
            items.forEach((item) => {
                if (item.dataType in json) {
                    item.dataType = "Object";
                }
            });
        }
        if ("parameters" in value) {
            processItems(value.parameters);
        } else if ("properties" in value) {
            processItems(value.properties);
        }
    });
}

function sortJSON(json: { [key: string]: MyAMethodInfo | MyAObjectInfo }) {
    Object.keys(json).forEach((key) => {
        const value = json[key];
        if ((value as MyAMethodInfo).hasOwnProperty("parameters")) {
            (value as MyAMethodInfo).parameters = (value as MyAMethodInfo).parameters.sort((a, b) => a.name.localeCompare(b.name));
        } else if ((value as MyAObjectInfo).hasOwnProperty("properties")) {
            (json[key] as MyAObjectInfo).properties = (json[key] as MyAObjectInfo).properties.sort((a, b) => a.name.localeCompare(b.name));
        }
    });
}

function handleMethodSummaryTag(target: ApiMethodData, element: Element): void {
    let summaryText = "";
    let remarksText = "";
    const summaryChildren = element.childNodes;
    summaryChildren.forEach((summaryChild, index) => {
        const summaryChildElement = summaryChild as Element;
        const tagName = summaryChildElement.nodeName;
        if (tagName === "para") {
            const paraText = extractPara(summaryChildElement, index !== summaryChildren.length - 1);
            summaryText += paraText;

            if (summaryChildElement.attributes.hasOwnProperty("beta")) {
                summaryText += "BETA_TAG_PLACEHOLDER";
            }
        } else if (tagName === "remarks") {
            remarksText += extractRemarks(summaryChildElement);
        } else {
            if (isTextTag(tagName)) {
                summaryText += summaryChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
            }
            if (isLinkTag(tagName)) {
                summaryText += extractLink(summaryChildElement);
            }
        }
    });
    target.description = summaryText.trimStart();
    if (remarksText) {
        target.remarks = remarksText.trimStart();
    }
}

function handleObjectSummaryTag(target: MyAObjectInfo, element: Element): void {
    let summaryText = "";
    let remarksText = "";
    const summaryChildren = element.childNodes;
    summaryChildren.forEach((summaryChild, index) => {
        const summaryChildElement = summaryChild as Element;
        const tagName = summaryChildElement.nodeName;
        if (tagName === "isSupported") {
            if (summaryChildElement.innerHTML === "true") {
                target.isSupported = true;
            }
        }
        if (isLinkTag(tagName)) {
            summaryText += extractLink(summaryChildElement);
        }
        if (tagName === "para") {
            const paraText = extractPara(summaryChildElement, index !== summaryChildren.length - 1);
            summaryText += paraText;
        }
        if (tagName === "remarks") {
            remarksText += extractRemarks(summaryChildElement);
        }
        if (isTextTag(tagName)) {
            summaryText += summaryChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });
    target.description = summaryText.trimStart();
    if (remarksText) {
        target.remarks = remarksText.trimStart();
    }
}

function extractRemarks(element: Element): string {
    let remarksText: string = "";
    const remarksChildren = element.childNodes;
    remarksChildren.forEach((remarksChild) => {
        const childTagName = remarksChild.nodeName;
        if (isTextTag(childTagName)) {
            remarksText += remarksChild.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
        if (isLinkTag(childTagName)) {
            remarksText += extractLink(remarksChild as Element);
        }
    });
    return remarksText;
}

function handleParamTag(target: MyAMethodInfo, element: Element, dataTypeArray: string[]): void {
    let descriptionText: string = "";
    const parameterChildren = element.childNodes;
    parameterChildren.forEach((parameterChild) => {
        const parameterChildElement = parameterChild as Element;
        const tagName = parameterChildElement.nodeName;
        if (isLinkTag(tagName)) {
            descriptionText += extractLink(parameterChildElement);
        }
        if (isTextTag(tagName)) {
            descriptionText += parameterChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });

    const parameterName = element.attributes.getNamedItem("name")?.nodeValue || "";
    const dataType = dataTypeArray.length > 0 ? dataTypeArray.shift()! : "Object";

    const paramDict: MyAParameterInfo = {
        name: parameterName,
        description: descriptionText,
        isRequired: element.attributes.hasOwnProperty("required"),
        isSupported: true,
        dataType: dataType
    };
    target.parameters.push(paramDict);
}

// only MyAdmin Methods have remarks
function handleRemarksTag(target: MyAMethodInfo, element: Element): void {
    let remarksText: string = "";
    const remarksChildren = element.childNodes;
    remarksChildren.forEach((remarksChild) => {
        const childTagName = remarksChild.nodeName;
        if (isTextTag(childTagName)) {
            remarksText += remarksChild.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });
    target.remarks = remarksText.trimStart();
}

function extractPara(element: Element, isLastElement: boolean): string {
    let paraText: string = "";
    let paraChildren = element.childNodes;
    paraChildren.forEach((paraChild) => {
        let paraChildElement = paraChild as Element;
        let tagName = paraChildElement.nodeName;
        if (isLinkTag(tagName)) {
            paraText += paraChildElement.outerHTML;
        }
        if (isTextTag(tagName)) {
            paraText += paraChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });
    if (isLastElement) {
        paraText += "\n";
    }
    return paraText;
}
