import { ReactNode } from "react";
import { Page } from "../../components";
import { PageTitleProps } from "../../components/PageTitle/PageTitle";
import { HeaderSections } from "../../components/Header/headerSectionsEnum";
import { TableOfContentsItem } from "../../components/TableOfContents/TableOfContents";
import { Card } from "@dev/zenith/dist/card/card";
import { CardContainer } from "@dev/zenith/dist/cardContainer/cardContainer";
import MyGeotabSystemSettings from "../../assets/images/zenith/introduction/zenithIntroductionSystemSettingsImage.png";
import MaintenanceOperations from "../../assets/images/zenith/introduction/zenithMaintenanceOperationsImage.png";
import HowToUseOne from "../../assets/images/zenith/introduction/zenithHowToUseOne.png";
import HowToUseTwo from "../../assets/images/zenith/introduction/zenithHowToUseTwo.png";
import HowToUseThree from "../../assets/images/zenith/introduction/zenithHowToUseThree.png";
import Ingredients from "../../assets/images/zenith/introduction/zenithIngredients.png";
import Meal from "../../assets/images/zenith/introduction/zenithMeal.png";
import Prep from "../../assets/images/zenith/introduction/zenithPrep.png";
import Recipe from "../../assets/images/zenith/introduction/zenithRecipe.png";
import "./introduction.scss";

const whatIsADesignSystem: ReactNode = (
    <div className="paragraph">
        <p>
            A design system is a combination of UI components, principles, and guidelines that are applied across an entire application. The combination of these elements define how a product should
            look and function consistently.
        </p>
        <p>At a more macro level, design systems break down the fundamentals of development into scalable elements:</p>
        <ul>
            <li>Electrons define the building blocks (spacing, color, typography) that make up the majority of a user's interface.</li>
            <li>Atoms are made up of electrons and signify simple, commonly used page elements (buttons, dropdown menus, banners, etc.)</li>
            <li>Organisms are more complex elements, made up of multiple nested atoms and more detailed specifications (toolbars, tables, side panels, etc.)</li>
            <li>Templates are generic skeletons for new, commonly used pages that are made up of organisms and atoms</li>
        </ul>
        <p>All of these elements are individually documented to detail the when and why you would use them, along with how to implement them.</p>
        <p>Think of a design system like a meal kit:</p>
        <div className="recipe-container">
            <CardContainer size={{ width: 168, height: 254 }} scrollable={true} >
                <div className="recipe-card">
                    <img src={Ingredients} alt="Ingredients" className="recipe-picture" />
                    <span>
                        <strong className="recipe-title">Ingredients</strong>
                    </span>{" "}
                    <span className="recipe-description">Fonts, colors, spacing, icons, components.</span>
                </div>
            </CardContainer>
            +
            <CardContainer size={{ width: 168, height: 254 }} scrollable={true}>
                <div className="recipe-card">
                    <img src={Recipe} alt="Recipe" className="recipe-picture"/>
                    <span>
                        <strong className="recipe-title">Recipes</strong>
                    </span>{" "}
                    <span className="recipe-description">Templates, guidelines, code snippets.</span>
                </div>
            </CardContainer>
            +
            <CardContainer size={{ width: 168, height: 254 }} scrollable={true}>
                <div className="recipe-card">
                    <img src={Prep} alt="Prep" className="recipe-picture"/>
                    <span>
                        <strong>Prep</strong>
                    </span>{" "}
                    <span className="recipe-description">Setup and process guides, principles.</span>
                </div>
            </CardContainer>
            =
            <CardContainer size={{ width: 168, height: 254 }}>
                <div className="recipe-card">
                    <img src={Meal} alt="Meal" className="recipe-picture"/>
                    <span>
                        <strong>Meal</strong>
                    </span>{" "}
                    <span className="recipe-description">Developed products, good food.</span>
                </div>
            </CardContainer>
        </div>
    </div>
);

const whyShouldYouUseOurDesignSystem: ReactNode = (
    <div className="paragraph">
        <p>While design systems are commonly applied only internally, we see a large opportunity with our integrator community to adopt Zenith.</p>
        <ol>
            <li>
                <strong>Stand on the shoulders of Geotab's design team</strong>
                <ol type="a">
                    <li>All Zenith Components are built to a rigorous standard, adhering to all WCAG 2.2 accessibility guidelines.</li>
                    <li>Components are also constructed with usability in mind, embedding all states and scaling directly into the components. No custom code required.</li>
                    <li>Robust design practices accompany all components, guiding you to mkae your integrations as usable as possible.</li>
                </ol>
            </li>
            <li>
                <strong>Reduction of development timelines and overhead</strong>
                <ol type="a">
                    <li>Cut-down on the building and rebuilding of commonly used components.</li>
                    <li>Single language for team members</li>
                    <li>Internally we have seen implemented Zenith pages increase speed to production by over 40%.</li>
                </ol>
            </li>
            <li>
                <strong>Zenith updates for everyone</strong>
                <ol type="a">
                    <li>New additions and updates will be added regularly, thoroughly tested to avoid breaking changes.</li>
                    <li>As the Zenith team makes updates, all properly implemented components will change automatically to reflect the updates, no additional coding required.</li>
                    <li>Validation of new components will happen before wider release, ensuring new updates don't break a user's experience.</li>
                </ol>
            </li>
        </ol>
    </div>
);

const howToUseZenith: ReactNode = (
    <div className="paragraph">
        <ol>
            <li>
                Follow our{" "}
                <a href="/zenith/setup" target="_blank" rel="noopener noreferrer" aria-label="Setup Guide Link">
                    setup guide
                </a>{" "}
                to connect your add-in to Zenith.
            </li>
            <li>Visit our storybook and find the component that you need.</li>
            <div>
                <img src={HowToUseOne} className="how-to-use-screenshot" alt="Storybook Screenshot" />
            </div>
            <li>Modify the parameters to follow the documented best practices to suit your needs</li>
            <div>
                <img src={HowToUseTwo} className="how-to-use-screenshot" alt="Modify parameters Screenshot" />
            </div>
            <li>Copy the snippet and paste it into your code</li>
            <div>
                <img src={HowToUseThree} className="how-to-use-screenshot" alt="Code Snippet Screenshot" />
            </div>
        </ol>
    </div>
);

const productSamples: ReactNode = (
    <div className="paragraph">
        <Card title="MyGeotab System Settings" size={"L"} className="product-samples-cards" >
            <Card.Content>
                <div>
                    <img src={MyGeotabSystemSettings} className="zenith__productSamples" alt="MyGeotab System Settings" />
                </div>
            </Card.Content>
        </Card>
        <Card title="Maintenance Operations" size={"L"} className="product-samples-cards" >
            <Card.Content>
                <div>
                    <img src={MaintenanceOperations} className="zenith__productSamples" alt="Maintenance Operations" />
                </div>
            </Card.Content>
        </Card>
    </div>
);

const learnMore: ReactNode = (
    <div className="paragraph">
        <a href="https://developers.geotab.com/zenith-storybook/" target="_blank" rel="noopener noreferrer" aria-label="Visit the Storybook documentation">
            Storybook
        </a>
    </div>
);

const howToGiveFeedback: ReactNode = (
    <div className="paragraph">
        <p>
            Please visit our Zenith <a href="https://community.geotab.com/s/group/0F9Pd0000003b33KAA/geotab-zenith" target="_blank" rel="noopener noreferrer" aria-label="zenith forum link">community page</a>  or contact <a href="mailto:zenith@geotab.com" target="_blank" rel="noopener noreferrer" aria-label="Feedback Email">
                zenith@geotab.com
            </a> if you have any questions or feedback.
        </p>
    </div>
);

const pageTitle: PageTitleProps = {
    title: "Zenith Introduction",
    breadCrumbItems: ["Zenith", "Introduction"],
    isBeta: true
};

const pageSections: TableOfContentsItem[] = [
    {
        elementId: "what-is-a-design-system",
        summary: "What is a design system?",
        details: whatIsADesignSystem
    },
    {
        elementId: "why-should-you-use-our-design-system",
        summary: "Why should you use our design system?",
        details: whyShouldYouUseOurDesignSystem
    },
    {
        elementId: "how-to-use-zenith",
        summary: "How to use Zenith",
        details: howToUseZenith
    },
    {
        elementId: "product-samples",
        summary: "Product samples",
        details: productSamples
    },
    {
        elementId: "learn-more",
        summary: "Learn more",
        details: learnMore
    },
    {
        elementId: "how-to-give-feedback",
        summary: "How to give feedback",
        details: howToGiveFeedback
    }
];

export default function Introduction() {
    return (
        <Page section={HeaderSections.Zenith} pageTitle={pageTitle} tableOfContents={pageSections}>
            <div className="paragraph">
                Zenith is Geotab's global design system built in React JS. Zenith enables all partners to create experiences that are easy to build, use, and learn
            </div>
        </Page>
    );
}
