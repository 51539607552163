import { useState, useContext } from "react";
import MenuContext from "../../menuContext";
import { LogoGeotabSDK } from "../Logo/LogoGeotabSDK";
import { Link } from "react-router-dom";
import { IconSearch } from "@geotab/react-component-library";
import SearchModal from "../SearchModal/SearchModal";
import "./header.scss";
import { HeaderSections } from "./headerSectionsEnum";
import { ErrorHandlerContext } from "../Error/ErrorBoundary";
import { Button } from "@dev/zenith";
import { ButtonType } from "@dev/zenith/dist/button/buttonType";
import { IconOpen } from "@dev/zenith/dist/icons/iconOpen";

export interface HeaderProps {
    isLandingPage: boolean;
}

export default function Header({ isLandingPage }: HeaderProps): JSX.Element {
    const { activeSiteSection, setActiveSiteSection } = useContext(MenuContext);
    const { resetError } = useContext(ErrorHandlerContext); // Access resetError directly through the context
    const [isSearchModalOpen, setSearchModalOpen] = useState<boolean>(false);
    const [isLanding, setIsLanding] = useState<boolean>(isLandingPage);

    const openSearchModal = (): void => {
        setSearchModalOpen(true);
    };

    const closeSearchModal = (): void => {
        setSearchModalOpen(false);
    };

    const handleLinkClick = (target: string): void => {
        // Close any open submenus in the NavBar before navigating to a new section to avoid the NavBar in the new section keeping the old section open
        let openNavBarSubMenu: HTMLButtonElement | null = document.querySelector(`button[data-state="open"]`);
        openNavBarSubMenu?.click();

        resetError();
        setActiveSiteSection(target);
        setIsLanding(false);
    };

    const handleAskDevelopersButtonClick = function () {
        window.open("https://community.geotab.com/s/topic/0TO2J000000gAUAWA2/developer-discussions?language=en_US&viewMore=1", "_blank", "noopener noreferrer");
    };

    const headerClass = isLandingPage ? "header-container landing-page-border" : "header-container";

    return (
        <div>
            <header className={headerClass}>
                <div className="header-container__left">
                    {isLanding && <LogoGeotabSDK className="geotab-sdk-logo" />}
                    <div className="header-menu-tabs">
                        <div className={`header-menu-tab ${activeSiteSection === HeaderSections.MyGeotab ? "active-button" : ""}`} data-testid="mygeotab-header-menu-button">
                            <Link to="/myGeotab/introduction" onClick={() => handleLinkClick(HeaderSections.MyGeotab)}>
                                MyGeotab
                            </Link>
                        </div>
                        <div className={`header-menu-tab ${activeSiteSection === HeaderSections.MyAdmin ? "active-button" : ""}`} data-testid="myadmin-header-menu-button">
                            <Link to="/myAdmin/introduction" onClick={() => handleLinkClick(HeaderSections.MyAdmin)}>
                                MyAdmin
                            </Link>
                        </div>
                        <div className={`header-menu-tab ${activeSiteSection === HeaderSections.Drive ? "active-button" : ""}`} data-testid="drive-header-menu-button">
                            <Link to="/drive/introduction" onClick={() => handleLinkClick(HeaderSections.Drive)}>
                                Drive
                            </Link>
                        </div>
                        <div className={`header-menu-tab ${activeSiteSection === HeaderSections.Hardware ? "active-button" : ""}`} data-testid="hardware-header-menu-button">
                            <Link to="/hardware/introduction" onClick={() => handleLinkClick(HeaderSections.Hardware)}>
                                Hardware
                            </Link>
                        </div>
                        <div className={`header-menu-tab ${activeSiteSection === HeaderSections.Zenith ? "active-button" : ""}`} data-testid="zenith-header-menu-button">
                            <Link to="/zenith/introduction" onClick={() => handleLinkClick(HeaderSections.Zenith)}>
                                Zenith
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="header__container__right">
                    <Button
                        onClick={handleAskDevelopersButtonClick}
                        type={ButtonType.Tertiary}
                        title="Ask a Developer"
                        className="header-ask-developer-button"
                        aria-label="Ask a developer in Geotab discussions"
                    >
                        Ask Developers
                        <IconOpen />
                    </Button>
                    <Button onClick={openSearchModal} type={ButtonType.Secondary} title="Search" aria-label="Search" className="header-search-button">
                        <IconSearch />
                        Search...
                    </Button>
                </div>
            </header>
            <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
        </div>
    );
}
