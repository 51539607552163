import { HashLink } from "react-router-hash-link";
import { HeaderSections } from "../../Header/headerSectionsEnum";
import BetaPill from "../../BetaPill/BetaPill";
import { SPECIAL_CHAR_REGEX } from "./parserUtils";

export default function renderStringWithUrl(name: string, text: string, headerSection: HeaderSections): JSX.Element {
    const linkBase = getLinkBase(headerSection);
    const formattedText = preprocessText(text);
    const lines = splitTextIntoLines(formattedText);

    let renderedText: JSX.Element[] = [];
    let listItems: JSX.Element[] = [];

    lines.forEach((line, index) => {
        if (line.trim() === "") {
            return;
        }
        const isListItem = isLineListItem(line);
        const content = getContentFromLine(isListItem, line);
        const segments = processSegments(content, linkBase);

        if (isListItem) {
            listItems.push(<li key={`li-${name}-${index}`}>{segments}</li>);
        } else {
            if (listItems.length > 0) {
                renderedText.push(<ul key={`ul-${renderedText.length}`}>{listItems}</ul>);
                listItems = [];
            }
            renderedText.push(<div key={`p-${index}`}>{segments}</div>);
        }
    });

    if (listItems.length > 0) {
        renderedText.push(<ul key={`ul-${renderedText.length}`}>{listItems}</ul>);
    }

    return <div>{renderedText}</div>;
}

function getLinkBase(headerSection: HeaderSections): string {
    return headerSection === HeaderSections.MyGeotab
        ? "/myGeotab/apiReference"
        : "/myAdmin/apiReference";
}

function preprocessText(text: string): string {
    const unescapedText = unescapeText(text);
    const decodedText = decodeText(unescapedText);
    return replaceBreakTags(decodedText);
}

function unescapeText(text: string): string {
    const escapeRegex = /\\('|"|\\|\/|b|f|n|r|t|u[0-9A-Fa-f]{4})/g;
    return text.replace(escapeRegex, (_, escapeSequence) => {
        switch (escapeSequence) {
            case "\\'": return "'";
            case '\\"': return '"';
            case "\\\\": return "\\";
            case "\\/": return "/";
            case "\\b": return "\b";
            case "\\f": return "\f";
            case "\\n": return "\n";
            case "\\r": return "\r";
            case "\\t": return "\t";
            default: return escapeSequence.startsWith("\\u")
                ? String.fromCharCode(parseInt(escapeSequence.substr(2), 16))
                : escapeSequence;
        }
    });
}

function decodeText(text: string): string {
    return text.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
}

function splitTextIntoLines(text: string): string[] {
    return text.split("\n");
}

function isLineListItem(line: string): boolean {
    return line.trim().startsWith("- ");
}

function getContentFromLine(isListItem: boolean, line: string): string {
    return isListItem ? line.replace(/^\s*-\s*/, "") : line;
}

function processSegments(content: string, linkBase: string): JSX.Element[] {
    const segments: JSX.Element[] = [];
    let currentIndex = 0;

    const patterns = [
        { regex: /<see cref="([^"]*)"[^>]*>/g, handler: handleSeeTag },
        { regex: /<see><cref>([^"]*)<\/cref><\/see>/g, handler: handleSee2Tag },
        { regex: /<see href="([^"]*)">(.*?)<\/see>/g, handler: handleAnchorTag },
        { regex: /<a href="([^"]*)">(.*?)<\/a>/g, handler: handleAnchorTag },
        { regex: /BETA_TAG_PLACEHOLDER/g, handler: handleBetaTag }
    ];

    patterns.forEach(({ regex, handler }) => {
        let match;
        let keyIndex = 0;
        while ((match = regex.exec(content)) !== null) {
            const start = currentIndex;
            const end = match.index;
            if (start < end) {
                segments.push(<span key={`span-${currentIndex}-${start}`}>{content.slice(start, end)}</span>);
            }
            handler(match, segments, linkBase, keyIndex);
            keyIndex++;
            currentIndex = regex.lastIndex;
        }
    });

    const remainingText = content.slice(currentIndex).replace(/\/>/g, "");
    if (remainingText) {
        segments.push(<span key={`span-${currentIndex}-${remainingText}`}>{remainingText}</span>);
    }

    return segments;
}

function handleSeeTag(match: RegExpExecArray, segments: JSX.Element[], linkBase: string, keyIndex: number) {
    const memberName = match[1];
    const linkText = extractLinkText(memberName);
    const link = determineLink(memberName, linkBase, linkText);

    segments.push(
        <HashLink key={`hashlink-see-${keyIndex}`} to={link}>
            {linkText}
        </HashLink>
    );
}

function handleSee2Tag(match: RegExpExecArray, segments: JSX.Element[], _: string, keyIndex: number) {
    const memberName = match[1];
    const linkText = extractLinkText(memberName);
    const link = `#${linkText}`;

    segments.push(
        <HashLink key={`hashlink-see2-${keyIndex}`} to={link}>
            {linkText}
        </HashLink>
    );
}

function handleAnchorTag(match: RegExpExecArray, segments: JSX.Element[], _: string, keyIndex: number) {
    const link = match[1];
    const linkText = match[2];

    segments.push(
        <a key={`a-${keyIndex}`} href={link}>
            {linkText}
        </a>
    );
}

function handleBetaTag(_: RegExpExecArray, segments: JSX.Element[], _0: string, keyIndex: number) {
    segments.push(<BetaPill key={`beta-pill-${keyIndex}`} />);
}

function extractLinkText(memberName: string): string {
    const cleanString = memberName.replace(/\(.*\)/, "");
    const methodName = cleanString.substring(cleanString.lastIndexOf(".") + 1);
    const cref = methodName.split(".");
    const linkText = cref[cref.length - 1].replace(SPECIAL_CHAR_REGEX, "");
    return linkText === "IAsyncEnumerable1" ? "enumerable" : linkText === "FeedResult1" ? "FeedResult" : linkText;
}

function determineLink(memberName: string, linkBase: string, linkText: string): string {
    if (memberName.includes(":System")) {
        return `https://docs.microsoft.com/en-us/dotnet/api/${memberName.slice(2).toLowerCase()}`;
    } else if (memberName.includes("T:") || memberName.includes("Settings")) {
        return `${linkBase}/objects/${linkText}`;
    } else if (memberName.includes("M:")) {
        return `${linkBase}/methods/${linkText}`;
    } else {
        return `#${linkText}`;
    }
}

function replaceBreakTags(text: string): string {
    return text.replace(/<br\/>/g, "\n");
}
