import { useParams, Navigate } from "react-router-dom";
import { HeaderSections } from "../Header/headerSectionsEnum";
import renderStringWithUrl from "./utils/renderStringWithUrl";
import { CodeSamples } from "../CodeSamplesContainer";
import { methodsCodeSamples } from "./utils/methodsCodeSamples";
import ApiMethod, { ApiMethodData, ApiMethodProps } from "./ApiMethod";
import { ReferenceSections } from "./referenceSectionsEnum";
import { WIP_STRING } from "./utils/apiReferenceUtils";
import { useEffect, useState } from "react";
import { referenceDataPromise } from "./ApiReference";
import { Waiting } from "@dev/zenith";
import InformationalBox from "../InformationalBox/InformationalBox";
import "./reference.scss";

interface DynamicApiMethodProps {
    headerSection: HeaderSections;
    referenceSection: ReferenceSections;
}

export default function DynamicApiMethod({ headerSection, referenceSection }: DynamicApiMethodProps): JSX.Element {
    let pageInfo: ApiMethodProps = {} as ApiMethodProps;
    const { methodId = "" } = useParams();
    const methodDataString: string | null = sessionStorage.getItem(methodId + referenceSection);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        referenceDataPromise
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("An error occurred while processing the methods:", error);
                setIsLoading(false);
            });
    }, [isLoading]);

    if (isLoading) {
        return <Waiting isLoading />; //TODO: Check Loading Spinner
    }

    if (!methodDataString) {
        return <Navigate to="/not-found" replace />;
    }

    const storedMethodData: ApiMethodData = JSON.parse(methodDataString);
    pageInfo.parameters = storedMethodData.parameters;
    pageInfo.roles = storedMethodData.roles;
    const returnValueDescriptions: string = storedMethodData.returns;
    const javascriptCodeSampleFromXML: string = storedMethodData.example;
    const displayBetaTag: boolean | undefined = storedMethodData.isBeta;
    let codeSamples: CodeSamples = `${referenceSection}.${methodId}` in methodsCodeSamples ? methodsCodeSamples[`${referenceSection}.${methodId}`] : { javascript: WIP_STRING };
    if (javascriptCodeSampleFromXML && javascriptCodeSampleFromXML !== "") {
        // Format the JS code sample that is pulled from the XML since there's extra whitespace
        codeSamples.javascript = javascriptCodeSampleFromXML
            .replaceAll("               ", "    ")
            .replaceAll("             ", "    ")
            .replace(/(\s*)}\);/, "\n});")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")
            .trim();
    }
    pageInfo.codeSamples = codeSamples;
    const introductionDescription: string = JSON.parse(sessionStorage[methodId + referenceSection]).description;
    const remarksDescription: string = storedMethodData.remarks ? String(storedMethodData.remarks) : "";
    const remarksContent = remarksDescription ? <InformationalBox>{renderStringWithUrl(methodId, remarksDescription, headerSection)}</InformationalBox> : "";

    pageInfo.introduction = (
        <>
            {renderStringWithUrl(methodId, introductionDescription, headerSection)}
            {remarksContent}
        </>
    );

    pageInfo.returns = renderStringWithUrl(methodId, returnValueDescriptions, headerSection);
    pageInfo.rateLimits = storedMethodData.rateLimits;
    pageInfo.title = {
        title: methodId + " (...)",
        breadCrumbItems: [referenceSection, "API Reference", "Methods", methodId + " (...)"],
        isBeta: displayBetaTag
    };
    pageInfo.headerSection = headerSection;

    return <ApiMethod {...pageInfo} />;
}
