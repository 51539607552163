import { RateLimit } from "./apiReferenceUtils";
import { ApiMethodParameter, ApiMethodData } from "../ApiMethod";
import { ApiObjectProperty, ApiObjectData } from "../ApiObject";
import { extractCodeReference, extractMember, extractLink, handleReturnsTag, isId, isLinkTag, isTextTag, WHITESPACE_REGEX } from "./parserUtils";
import { methodRateLimitsMapping, objectRateLimitsMapping } from "./rateLimits";

interface MyGParameterInfo extends ApiMethodParameter {
    isSupported: boolean;
    isRequired: boolean;
    isBeta: boolean;
}

interface MyGPropertyInfo extends ApiObjectProperty {
    isSupported: boolean;
    isBeta: boolean;
}

export interface MyGMethodInfo extends ApiMethodData {
    parameters: MyGParameterInfo[];
    isSupported: boolean;
    isBeta: boolean;
    rateLimits: RateLimit[];
}

export interface MyGObjectInfo extends ApiObjectData {
    properties: MyGPropertyInfo[];
    isSupported: boolean;
    hasValues: boolean;
    isBeta: boolean;
    baseType?: string;
}

export interface MyGParserOutput {
    [name: string]: MyGMethodInfo | MyGObjectInfo;
}

const CREDENTIALS_METHOD_PARAMETERS: MyGParameterInfo = {
    name: "credentials",
    description: 'The user\'s Geotab login <see cref="T:Geotab.Checkmate.ObjectModel.Credentials" />.',
    isRequired: true,
    isBeta: false,
    dataType: "Object",
    isSupported: true
};

const TYPENAME_METHOD_PARAMETERS: MyGParameterInfo = {
    name: "typeName",
    description: 'Identifies the type of entity that is being passed to the next parameter. For example, <see cref="T:Geotab.Checkmate.ObjectModel.Device" />.',
    isRequired: true,
    dataType: "String",
    isBeta: false,
    isSupported: true
};

export default function myGParser(xml: XMLDocument, itemType: string): MyGParserOutput {
    let json: { [key: string]: MyGMethodInfo | MyGObjectInfo } = {};

    const processNode = (node: Element) => {
        const memberName = node.attributes.getNamedItem("name")?.nodeValue ?? "";

        if (itemType === "method" && isMethod(memberName)) {
            const methodName: string = extractMethodName(memberName).replace(/Async$/, "");
            const methodInfo = parseMethodInfo(methodName, node, memberName);
            if (!(methodName in json)) {
                json[methodName] = methodInfo;
            }
        } else {
            const memberNameComponent: string[] = memberName.split(".");
            if (itemType === "object" && isObject(memberName)) {
                let objectName: string = memberNameComponent[memberNameComponent.length - 1];
                objectName = getStringBeforeBacktick(objectName);
                const objectInfo = parseObjectInfo(objectName, node);
                if (!(objectName in json) && objectName !== "DataStore" && objectInfo.isSupported) {
                    json[objectName] = objectInfo;
                }
            } else if (itemType === "object" && isObjectProperty(memberName)) {
                let objectName: string = memberNameComponent[memberNameComponent.length - 2];
                objectName = getStringBeforeBacktick(objectName);
                const objectPropertyName: string = memberNameComponent[memberNameComponent.length - 1];
                const objectPropertyInfo = parseObjectPropertiesInfo(node, objectPropertyName);
                if (objectName in json && json[objectName].hasOwnProperty("properties") && objectPropertyInfo.isSupported) {
                    (json[objectName] as MyGObjectInfo).properties.push(objectPropertyInfo);
                    (json[objectName] as MyGObjectInfo).hasValues = memberName.startsWith("F:");
                }
            }
        }
    };

    const members = xml.documentElement.getElementsByTagName("member");
    Array.from(members).forEach(memberNode => processNode(memberNode));
    transformJSON(json);
    sortJSON(json);
    return json;
}

function extractMethodName(input: string): string {
    const webMethodsMatch: RegExpMatchArray | null = input.match(/WebMethods\.([a-zA-Z]+)/);
    const dataStoreMatch: RegExpMatchArray | null = input.match(/DataStore\.([a-zA-Z]+)/);

    if (webMethodsMatch) {
        return webMethodsMatch[1];
    } else if (dataStoreMatch) {
        return dataStoreMatch[1];
    }
    return "";
}

function removeGetsOrSetsDescription(description: string): string {
    let capitalizedResult = description;
    const regexGetsOrSets: RegExp = /^(Gets or sets)\s+/i;
    const regexGets: RegExp = /^(Gets)\s+/i;
    let result;
    if (description.startsWith("Gets or sets")) {
        result = description.replace(regexGetsOrSets, ""); // Remove "Gets or sets" from the beginning
    } else if (description.startsWith("Gets")) {
        result = description.replace(regexGets, ""); // Remove "Gets" from the beginning
    }
    if (result) {
        capitalizedResult = result.charAt(0).toUpperCase() + result.slice(1); // Capitalize the first letter and make the rest lowercase
    }
    return capitalizedResult;
}

function isMethod(memberName: string): boolean {
    return memberName.includes("M:CheckmateServer.Web.WebMethods") || memberName.includes("M:Geotab.Checkmate.Database.DataStore");
}

function isObject(memberName: string): boolean {
    return (
        memberName.includes("T:") &&
        !memberName.includes("T:Geotab.StoreForward") &&
        !memberName.includes("T:Geotab.Checkmate.ObjectModel.UnitConversion") &&
        !memberName.includes("T:CheckmateServer.Web.WebMethods")
    );
}

function isObjectProperty(memberName: string): boolean {
    return memberName.includes("P:") || memberName.includes("F:");
}

function isMyGObject(parameterName: string | null): boolean {
    if (parameterName && typeof window !== "undefined") {
        const storageItem = parameterName.charAt(0).toUpperCase() + parameterName.slice(1) + "MYG";
        return JSON.parse(sessionStorage.getItem(storageItem)!)?.hasOwnProperty("properties");
    } else {
        return false;
    }
}

function parseMethodInfo(methodName: string, xmlMemberElement: Element, memberName: string): MyGMethodInfo {
    let method: MyGMethodInfo = {
        description: "",
        parameters: [],
        returns: "",
        example: "",
        isSupported: true,
        isBeta: false,
        rateLimits: []
    };
    if (isGenericMethod(memberName)) {
        method.parameters.push(TYPENAME_METHOD_PARAMETERS);
    }
    const dataTypeArray: string[] = getParameterList(memberName);
    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChildElement.nodeName;
        switch (tagName) {
            case "example":
                handleExampleTag(method, memberChildElement);
                break;
            case "isSupported":
                handleSupportedTag(method, memberChildElement);
                break;
            case "param":
                if (
                    memberChildElement.attributes.hasOwnProperty("jsHide") ||
                    memberChildElement.getAttribute("name") === "context" ||
                    memberChildElement.getAttribute("name") === "cancellationToken" ||
                    memberChildElement.getAttribute("name") === "dataStore"
                ) {
                    dataTypeArray.shift()!;
                } else {
                    handleParamTag(method, memberChildElement, dataTypeArray);
                }
                break;
            case "rateLimit":
                handleMethodRateLimitsTag(methodName, method, memberChildElement);
                break;

            case "returns":
                handleReturnsTag(method, memberChildElement);
                break;
            case "summary":
                handleMethodSummaryTag(method, memberChildElement);
                break;
            default:
                break;
        }
    });
    return method;
}

function parseObjectInfo(objectName: string, xmlMemberElement: Element): MyGObjectInfo {
    let object: MyGObjectInfo = {
        description: "",
        properties: [],
        isBeta: false,
        isSupported: true,
        hasValues: false
    };

    const baseTypeMemberName = xmlMemberElement.attributes.getNamedItem("baseType")?.nodeValue || null;
    if (baseTypeMemberName) {
        object.baseType = extractMember(baseTypeMemberName);
    }

    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChildElement.nodeName;
        if (tagName === "isSupported") {
            if (memberChildElement.attributes.hasOwnProperty("beta")) {
                object.isBeta = true;
            }
        }
        if (tagName === "paging") {
            handlePagingTag(object, memberChildElement);
        }
        if (tagName === "rateLimit") {
            if (!object.rateLimits) {
                object.rateLimits = [];
            }
            handleObjectRateLimitsTag(objectName, object, memberChildElement);
        }
        if (tagName === "summary") {
            handleObjectSummaryTag(object, memberChildElement);
        }
    });
    return object;
}

function parseObjectPropertiesInfo(xmlMemberElement: Element, propertyName: string): MyGPropertyInfo {
    let objectProperty: MyGPropertyInfo = {
        name: propertyName,
        description: "",
        isBeta: false,
        isSupported: true,
        dataType: ""
    };

    const codeReference = extractCodeReference(xmlMemberElement);
    const member = extractMember(codeReference);
    const dataType = codeReference && isId(member) ? "String" : member;
    objectProperty.dataType = dataType;

    let descriptionText = "";
    const memberChildren = xmlMemberElement.childNodes;
    memberChildren.forEach((memberChild) => {
        const memberChildElement = memberChild as Element;
        const tagName = memberChildElement.nodeName;
        if (tagName === "isSupported") {
            if (memberChildElement.innerHTML === "false" || memberChildElement.innerHTML === "false.") {
                objectProperty.isSupported = false;
            }
            if (memberChildElement.attributes.hasOwnProperty("beta")) {
                objectProperty.isBeta = true;
            }
        }
        if (tagName === "summary") {
            const summaryChildren = memberChildElement.childNodes;
            summaryChildren.forEach((summaryChild) => {
                const summaryChildElement = summaryChild as Element;
                const summaryTagName = summaryChildElement.nodeName;
                if (summaryTagName === "isSupported") {
                    if (summaryChildElement.innerHTML === "false" || summaryChildElement.innerHTML === "false.") {
                        objectProperty.isSupported = false;
                    }
                }
                const text = extractPropertySummary(summaryChildElement);
                descriptionText += text;
            });
        }
    });
    objectProperty.description = removeGetsOrSetsDescription(descriptionText.trimStart());
    return objectProperty;
}

function isGenericMethod(memberName: string): boolean {
    const memberNameWithoutParameters = removeEndingSubstring(memberName);
    return memberNameWithoutParameters.endsWith("``1") || memberNameWithoutParameters.endsWith("``2");
}

function removeEndingSubstring(memberName: string): string {
    const regex: RegExp = /\([^()]*\)$/;
    return memberName.replace(regex, "");
}

// Parses a method signature and returns an array of parameter data types, including handling for asynchronous tasks and cancellation tokens.
// Example input: M:CheckmateServer.Web.WebMethods.AuthenticateAsync(Microsoft.AspNetCore.Http.HttpContext,System.String,System.String,System.String,System.String,System.Boolean,System.Threading.CancellationToken@)
// Example output: ['HttpContext', 'String', 'String', 'String', 'String', 'Boolean', 'CancellationToken']
function getParameterList(memberName: string): string[] {
    const openingParenthesisIndex = memberName.indexOf("(");
    const parametersString = openingParenthesisIndex > -1 ? memberName.substring(openingParenthesisIndex + 1, memberName.length - 1) : "";
    const parameterArray = parametersString
        .split(",")
        .filter((item) => item.trim() !== "``0" && item.trim() !== "")
        .map((item) => {
            let member = extractMemberFromFullyQualifiedName(item);
            return isMyGObject(member) ? (member = "Object") : member;
        });
    return parameterArray;
}

function extractMemberFromFullyQualifiedName(fqn: string): string {
    const nullablePattern = /Nullable\{\s*System\.\s*([A-Za-z0-9]+)\s*\}/;
    const typePattern = /([A-Za-z0-9]+)/;
    const prefixPattern = /(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*/;

    const nullableRegex = new RegExp(`${prefixPattern.source}${nullablePattern.source}`);
    const regularRegex = new RegExp(`${prefixPattern.source}${typePattern.source}`);

    const nullableMatch = nullableRegex.exec(fqn);
    if (nullableMatch) {
        return nullableMatch[1]; // Return the type inside the Nullable<> brackets
    }
    const regularMatch = regularRegex.exec(fqn);
    if (regularMatch) {
        return regularMatch[1]; // Return the type without the Nullable<> brackets
    }
    return fqn;
}

function transformJSON(json: { [key: string]: MyGMethodInfo | MyGObjectInfo }) {
    Object.keys(json).forEach((key) => {
        let value = json[key];

        if ("properties" in value) {
            value.properties.forEach(item => {
                if (item.dataType in json && "baseType" in json[item.dataType] && (json[item.dataType] as MyGObjectInfo).baseType === "String") {
                    item.dataType = "String";
                }
            });
        }
    });


    Object.keys(json).forEach((key) => {
        let value = json[key];

        if ("properties" in value) {
            value.properties.forEach(item => {
                if (item.dataType in json) {
                    item.dataType = "Object";
                }
            });
            while ("baseType" in value) {
                const baseType: string | undefined = value.baseType;
                if (baseType === undefined) {
                    break;
                }
                if (baseType in json) {
                    const current = json[key] as MyGObjectInfo;
                    const base = json[baseType] as MyGObjectInfo;

                    if ("properties" in current && "properties" in base) {
                        const currentProperties = current.properties;
                        const baseProperties = base.properties;
                        const mergedProperties = [
                            ...currentProperties,
                            ...baseProperties.filter(baseProp =>
                                !currentProperties.some(currentProp => currentProp.name === baseProp.name)
                            )
                        ];

                        current.properties = mergedProperties;
                    }
                    value = json[baseType];
                } else {
                    break;
                }
            }
            const isRecordedObject = key in json;
            const hasBaseType = (json[key] as MyGObjectInfo).baseType;
            if (isRecordedObject && hasBaseType) {
                delete (json[key] as MyGObjectInfo).baseType;
            }
        }
    });
}

function sortJSON(json: { [key: string]: MyGMethodInfo | MyGObjectInfo }) {
    Object.keys(json).forEach((key) => {
        if (key in json && (json[key] as MyGMethodInfo).hasOwnProperty("parameters")) {
            (json[key] as MyGMethodInfo).parameters = (json[key] as MyGMethodInfo).parameters.sort((a, b) => a.name.localeCompare(b.name));
        } else if (key in json && (json[key] as MyGObjectInfo).hasOwnProperty("properties")) {
            (json[key] as MyGObjectInfo).properties = (json[key] as MyGObjectInfo).properties.sort((a, b) => a.name.localeCompare(b.name));
        }
    });
}

function getStringBeforeBacktick(input: string): string {
    const regex = /^(.*?)`/;
    const match = regex.exec(input);
    if (match) {
        return match[1]; // Return the string before the first backtick
    }
    return input; // Return the original string if no backtick is found
}

// currently, only MyGeotab has examples
function handleExampleTag(target: MyGMethodInfo, element: Element): void {
    let codeText = "";
    const exampleChildren = element.childNodes;
    exampleChildren.forEach((exampleChild) => {
        const exampleChildElement = exampleChild as Element;
        const tagName = exampleChildElement.nodeName;
        if (tagName === "code") {
            codeText += exampleChildElement.innerHTML;
        }
    });
    target.example = codeText.trim();
}

function handleMethodSummaryTag(target: ApiMethodData, element: Element): void {
    let summaryText = "";
    const summaryChildren = element.childNodes;
    summaryChildren.forEach((summaryChild, index) => {
        const summaryChildElement = summaryChild as Element;
        const tagName = summaryChildElement.nodeName;
        if (tagName === "para") {
            const paraText = extractPara(summaryChildElement);
            summaryText += paraText;
            if (summaryChildElement.attributes.hasOwnProperty("beta")) {
                summaryText += " BETA_TAG_PLACEHOLDER ";
            }
            if (index !== summaryChildren.length - 1) {
                summaryText += "\n";
            }
        }
        else if (isTextTag(tagName)) {
            summaryText += summaryChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
        else if (tagName === "list") {
            summaryText += extractList(summaryChildElement);
        }
        else if (isLinkTag(tagName)) {
            summaryText += extractLink(summaryChildElement);
        }
    });
    target.description = summaryText.trimStart();
}

// only MyGeotab has rate limits
function handleObjectRateLimitsTag(objectName: string, target: MyGObjectInfo, element: Element): void {
    const name = element.getAttribute("method") || "";
    const limit = element.getAttribute("limit") || "";
    const period = element.getAttribute("period") || "";
    const description = element.innerHTML;
    const hasActiveAttribute = element.attributes.hasOwnProperty("active");
    const isActive = element.getAttribute("active") === "true";

    const rateLimit: RateLimit = {
        name: name,
        limit: limit,
        period: period,
        description: description,
        // TODO - add when xml is updated
        // status: hasActiveAttribute && isActive ? "Active" : "Coming soon"

        status:
            (hasActiveAttribute && isActive) ||
                objectRateLimitsMapping[objectName.trim().toLowerCase()]?.[" * "] ||
                objectRateLimitsMapping[" * "]?.[name.trim().toLowerCase()] ||
                objectRateLimitsMapping[objectName.trim().toLowerCase()]?.[name.trim().toLowerCase()] ?
                "Active" :
                "Coming soon"
    };

    target.rateLimits?.push(rateLimit);
}

function handleObjectSummaryTag(target: MyGObjectInfo, element: Element): void {
    let summaryText = "";
    const summaryChildren = element.childNodes;
    summaryChildren.forEach((summaryChild, index) => {
        const summaryChildElement = summaryChild as Element;
        const tagName = summaryChildElement.nodeName;
        if (isLinkTag(tagName)) {
            summaryText += extractLink(summaryChildElement);
        }
        if (tagName === "list") {
            summaryText += extractList(summaryChildElement);
        }
        if (tagName === "para") {
            const paraText = extractPara(summaryChildElement);
            summaryText += paraText;
            if (index !== summaryChildren.length - 1) {
                summaryText += "\n";
            }
        }
        if (isTextTag(tagName)) {
            summaryText += summaryChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });
    target.description = removeGetsOrSetsDescription(summaryText.trimStart());
}

// only MyGeotab has paging
function handlePagingTag(target: MyGObjectInfo, element: Element): void {
    target.pagination = {
        supportedSorts: [],
        resultsLimit: "",
        isBeta: false,
        dateSortProperty: undefined
    };

    target.pagination.supportedSorts = element.getAttribute("sortSupported")?.split(";") ?? [];
    target.pagination.resultsLimit = element.getAttribute("limit") ?? "";
    target.pagination.isBeta = element.attributes.hasOwnProperty("beta");
    if (element.hasAttribute("dateSortProperty")) {
        target.pagination.dateSortProperty = element.getAttribute("dateSortProperty") ?? "";
    }
}

function handleParamTag(target: MyGMethodInfo, element: Element, dataTypeArray: string[]): void {
    let descriptionText = "";
    const parameterChildren = element.childNodes;
    parameterChildren.forEach((parameterChild) => {
        const parameterChildElement = parameterChild as Element;
        const tagName = parameterChildElement.nodeName;
        if (isLinkTag(tagName)) {
            descriptionText += extractLink(parameterChildElement);
        }
        if (tagName === "list") {
            descriptionText += extractList(parameterChildElement);
        }
        if (isTextTag(tagName)) {
            descriptionText += parameterChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });

    const parameterName = element.attributes.getNamedItem("name")?.nodeValue || "";
    const dataType =
        dataTypeArray.length > 0
            ? dataTypeArray.shift()!
            : "Object";

    const paramDict: MyGParameterInfo = {
        name: parameterName,
        description: removeGetsOrSetsDescription(descriptionText.trim()),
        isRequired: element.attributes.hasOwnProperty("required"),
        isBeta: element.attributes.hasOwnProperty("beta"),
        dataType: dataType,
        isSupported: true
    };
    target.parameters.push(paramDict);
}

// only MyGeotab has rate limits
function handleMethodRateLimitsTag(methodName: string, target: MyGMethodInfo, element: Element): void {
    const limit = element.getAttribute("limit") || "";
    const period = element.getAttribute("period") || "";
    const description = element.innerHTML;
    const hasActiveAttribute = element.attributes.hasOwnProperty("active");
    const isActive = element.getAttribute("active") === "true";

    const rateLimit: RateLimit = {
        name: methodName,
        limit: limit,
        period: period,
        description: description,
        // TODO - add when xml is updated
        // status: hasActiveAttribute && isActive ? "Active" : "Coming soon"
        // TODO - remove when xml is updated
        status: (hasActiveAttribute && isActive) || methodRateLimitsMapping[" * "] || methodRateLimitsMapping[methodName] ? "Active" : "Coming soon"
    };

    target.rateLimits.push(rateLimit);
}

function handleSupportedTag(target: MyGMethodInfo, element: Element): void {
    if (element.attributes.hasOwnProperty("beta")) {
        target.isBeta = true;
    }
    if (element.innerHTML === "false" || element.innerHTML === "false.") {
        target.isSupported = false;
    }
    if (!element.hasAttribute("noAuthenticationNeeded")) {
        target.parameters.push(CREDENTIALS_METHOD_PARAMETERS);
    }
}

// only MyGeotab has lists
function extractList(listElement: Element): string {
    let listText = "";
    let items = listElement.childNodes;
    items.forEach((item) => {
        let itemContents = item.childNodes;
        itemContents.forEach((content) => {
            if (content.nodeName === "description") {
                if (content.childNodes[0].nodeName === "see") {
                    listText += `\n- ${(content.childNodes[0] as Element).outerHTML}`;
                }
                else {
                    listText += `\n- ${content.childNodes[0].nodeValue?.replace(WHITESPACE_REGEX, " ") ?? ""}`;
                }
            }
            else if (content.nodeName === "list") {
                listText += extractList(content as Element);
            }
        });
    });
    return listText;
}

function extractPara(element: Element): string {
    let paraText: string = "";
    let paraChildren = element.childNodes;
    paraChildren.forEach((paraChild) => {
        let paraChildElement = paraChild as Element;
        let tagName = paraChildElement.nodeName;
        if (isLinkTag(tagName)) {
            paraText += paraChildElement.outerHTML;
        }
        if (tagName === "list") {
            paraText += extractList(paraChildElement);
        }
        if (isTextTag(tagName)) {
            paraText += paraChildElement.nodeValue?.replace(WHITESPACE_REGEX, " ");
        }
    });
    return paraText;
}

function extractPropertySummary(element: Element): string {
    let descriptionText = "";
    const tagName = element.nodeName;
    if (isLinkTag(tagName)) {
        descriptionText += extractLink(element);
    }
    if (tagName === "list") {
        descriptionText += extractList(element);
    }
    if (tagName === "para") {
        descriptionText += element.innerHTML;
    }
    if (isTextTag(tagName)) {
        descriptionText += element.nodeValue?.replace(WHITESPACE_REGEX, " ");
    }
    return descriptionText;
}
