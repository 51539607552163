import "@dev/zenith/dist/index.css";
import { LogoProps, SideNavigationMenuItemType } from "@geotab/react-component-library";
import { IconCircleInfo, IconStar} from "../icons";
import {IconConsole} from "@dev/zenith/dist/icons/iconConsole";

export const ZENITH_NAVBAR_ITEMS: SideNavigationMenuItemType[] = [
    {
        label: "Introduction",
        route: "/zenith/introduction",
        level: 0,
        order: 0,
        userTypes: [],
        roles: [],
        Icon: IconCircleInfo
    },
    {
        label: "Setup",
        route: "/zenith/setup",
        level: 0,
        order: 1,
        userTypes: [],
        roles: [],
        Icon: IconConsole as React.ForwardRefExoticComponent<LogoProps & React.RefAttributes<SVGSVGElement >>
    },
    {
        label: "Release Notes",
        route: "/zenith/releaseNotes",
        level: 0,
        order: 2,
        userTypes: [],
        roles: [],
        Icon: IconStar
    }
];
